/**
 * React.
 */
import * as React from "react"

/**
 * Formik.
 * https://formik.org/
 * https://formik.org/docs/overview
 */
import { Formik, Form, Field, ErrorMessage } from 'formik';

/**
 * Layout.
 * Used for basic page structure.
 */
import Layout from "../components/layout"

/**
 * SEO.
 * Used for page SEO content.
 */
import Seo from "../components/seo"

/**
 * Languages.
 * Text for translated page content.
 */
import LangEN from "../languages/LangEN"
import LangFR from "../languages/LangFR"

/**
 * Global variable(s).
 * 
 * Notes:
 * Set custom global variables for dev/prod environments.
 * 
 * Example Var:
 * GATSBY_ENVIRONMENT="Development"
 * 
 * To add/view value(s) in current file(s),
 * Development: {root}/.env.development
 * Production:  {root}/.env.production
 * 
 * To reference the variable(s) in code use,
 * let foo    = process.env.{VARIABLE-NAME1}
 * const blah = process.env.{VARIABLE-NAME2}
 */
const remoteUrl = process.env.GATSBY_REMOTE_URL

const UpdatePassword = ({ location }) => {

  /* Translate string to chosen language */
  function translate_string( str = '' ) {
    /* Set default language */
    let lang = LangEN
          
    /* Get URL so we can display page with chosen language */
    let window_url = ( typeof window !== 'undefined' ) ? window.location.href : ''
          
    /* Switch menu based on language value in url */
    if ( window_url.indexOf( "/fr/" ) > -1 ) {
      lang = LangFR
    }
          
    /* Convert string to language */
    for( var i in lang ) {
      if ( str === i ) {
        return lang[i]
      }
    }
  }

  /* Get URL params */
  const params = new URLSearchParams( location.search )

  /* Update new password */
  function updateNewPassword( new_password ) {
    /* Set form variables */
    const authUrl = remoteUrl + '/?rest_route=/simple-jwt-login/v1/user/reset_password';
    const data = {
        email: params.get( 'username' ), /* note: currently uses username value, not email value, using simple-jwt-plugin plugin */
        new_password: new_password,
        code: params.get( 'code' ),
    };
    const requestMetadata = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    
    /* Send the form data to Wordpress */
    fetch(authUrl, requestMetadata)
        .then(res => res.json()) 
        .then(result => {
          /* On response, what should we do? */
          if ( result.success ) {
            /* Show email sent message */
            document.getElementById( "register_status" ).innerHTML = '<span>' + translate_string( 'update-password-success' ) + ' <a href="../login/">' + translate_string( 'update-password-success-login' ) + '</a>.</span>'
            /* Reset form */
            document.getElementById( "new_password" ).value = '';
            document.getElementById( "repeat_password" ).value = '';
            /* Hide form fields */
            document.getElementById( "register_form_fields" ).style.display = 'none';
          } else {
            /* Show notice and reset form */
            document.getElementById( "register_status" ).innerHTML = '<span style="color:red;">Sorry, ' + result.data.message.toLowerCase() + ' Please try again.</span>'
            document.getElementById( "new_password" ).value = '';
            document.getElementById( "repeat_password" ).value = '';
            document.getElementById( "register_submit" ).disabled = false;
          }
        });

  }

  /**
   * Update password.
   * ( note: url params must contain both code and username ).
   */
  if ( null !== params.get( 'code' ) && null !== params.get( 'username' ) ) {
    return (
      <Layout>
      {/* SEO details */}
      <Seo title={translate_string( 'update-password-seo-title' )} /> 
      <div>
          <Formik
          /* Set form variables */
          initialValues={{ 
              new_password: '', 
              repeat_password: '', 
          }}
          validate={values => {
              const errors = {};
              /* Set errors for field values */ 
              if (!values.new_password) {
                errors.new_password = translate_string( 'update-password-password-required' )
              }  
              if (!values.repeat_password) {
                errors.repeat_password = translate_string( 'update-password-repeat-password' )
              }            
              if (values.new_password !== values.repeat_password) {
                errors.repeat_password = translate_string( 'update-password-passwords-no-match' )
              }                       
              return errors;
          }}
          /* On form submit */
          onSubmit={(values) => {
              setTimeout(() => {
                  /* Set form variables */
                  let form_values = JSON.stringify( values, null, 2 )
                  let jsonObj = JSON.parse( form_values );
                  /* Update password */
                  updateNewPassword( jsonObj.new_password )
              }, 400);
          }}
          >
          {({ isSubmitting }) => (
              <Form>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 p-1 text-center">
  
                        {/* Form details */} 
                        <h1 id="register_title">
                          {translate_string( 'update-password-title' )}
                        </h1>
  
                        {/* Form response when submitted */}
                        <p id='register_status'></p>
  
                      </div>
                    </div>
  
                    {/* Form fields */}
                    <div id="register_form_fields" className="col-md-12 p-1 text-center">

                        {/* Form introduction */}
                        <div>
                          <p>{translate_string( 'update-password-intro' )}</p>
                        </div>
  
                        {/* Form field - password */}
                        <div className="pb-2">                     
                            <Field id="new_password" type="password" name="new_password" placeholder={translate_string( 'update-password-new-password-placeholder' )} className="register-field" autoComplete="new-password" />
                            <ErrorMessage name="new_password" component="div" className="register-field-error d-flex justify-content-center" />
                        </div>  
  
                        {/* Form field - repeat password */}
                        <div className="pb-2">                     
                            <Field id="repeat_password" type="password" name="repeat_password" placeholder={translate_string( 'update-password-new-password-repeat' )} className="register-field" autoComplete="new-password" />
                            <ErrorMessage name="repeat_password" component="div" className="register-field-error d-flex justify-content-center" />
                        </div>
  
                        {/* Form submit button */}  
                        <div>
                            <button id="register_submit" 
                                    type="submit" 
                                    disabled={isSubmitting} 
                                    className="btn btn-outline-success"
                                    style={{width: '280px'}}>
                              {translate_string( 'update-password-submit' )}
                            </button>
                        </div>

                    </div>
                  </div>
              </Form>
            )}
            </Formik>
        </div>
      </Layout>
      )

  } else {

    /* Else, redirect to index */
    if ( typeof document !== 'undefined' ) {
      document.location.href = "./";
    }

    /* Return, we dont need to display anything */
    return ('')
  }

}

export default UpdatePassword
